// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


html {
	position: relative;
	min-height: 100%;
  text-align: justify;
  overflow-x: hidden;
}

body {
	background-color: #606062;
  font-family: 'Josefin Sans', sans-serif;
	margin-bottom: 60px;
  overflow-x: hidden;
}

.ul-nostyle {
	list-style-type: none;
}

.navbar {
	margin-bottom: 0;
}

.navbar-default {
  background-color: #A9CF46;
  border-color: #A9CF46;
}

.navbar-default .navbar-nav > li > a {
    color: #606062;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: #A9CF46;
}

.navbar-default .navbar-nav > li > a:hover, .nav > li > a:focus {
    background-color: #606062;
}



#navbarid {
  padding: 0;
}

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 60px;
	background-color: #A9CF46;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.container {
}

.wbgc {
  background-color: white;
  padding: 0;
}


/* HOME */

#home-about-a1, #home-fitness-a1, #home-recipes-a1 {
  color: #606062;
}

#home-about-a2, #home-fitness-a2, #home-recipes-a2 {
   color: #A9CF46;
}

#home-about-a1:hover, #home-fitness-a1:hover, #home-recipes-a1:hover,
#home-about-a2:hover, #home-fitness-a2:hover, #home-recipes-a2:hover, {
  color: #A9CF46;
  text-decoration: none;
}


#col-pad-about {
  padding: 39.6px - 22px 50px 0 50px;
}

@media (min-width: 1200px) {
  #col-pad-about {
  padding-top: 79.2px - 22px;
  padding-right: 0;
  padding-bottom: 39.6px;
  padding-left: 79.2px + 15px;
  width: 31%;
}
}

#col-pad-fitness {
  padding: 39.6px - 22px 50px 0 50px;
}

@media (min-width: 1200px) {
  #col-pad-fitness {
  padding: 79.2px - 22px 47px 39.6px 47px;
  width: 38%;
}
}

#col-pad-recipes {
  padding: 39.6px - 22px 50px 39.6px 50px;
}

@media (min-width: 1200px) {
  #col-pad-recipes {
  padding: 79.2px - 22px 79.2px + 15px 39.6px 0;
  width: 31%;
}
}

/* HOME */


/* ABOUT */

.about {
  padding: 79.2px - 22px 79.2px 26% 79.2px;
}

/*#about-p1 {
  padding-top: 28.6px;
  padding-bottom: 28.6px;
}*/

/* ABOUT */


/* EXERCISE */

.exercise {
  padding: 79.2px - 22px 79.2px 0 79.2px;
}

.exercise2 {
  height: 39.6px;
}

/*#exercise-p1 {
  padding-top: 28.6px;
  padding-bottom: 28.6px;
}

#exercise-p2 {
  padding-top: 39.6px;
}

#exercise-p3 {
  padding-top: 28.6px;
  padding-bottom: 28.6px;
}

#exercise-p4 {
  padding-bottom: 28.6px;
}*/

/* EXERCISE */


/* FITNESS */

.fitness {
  padding: 79.2px - 22px 79.2px 39.6px 79.2px;
}

/*#fitness-p1 {
  padding-top: 28.6px;
  padding-bottom: 28.6px;
}

#fitness-p2 {
  padding-bottom: 28.6px;
}*/

/* FITNESS */


/* RECIPES */

.recipes {
  padding: 79.2px - 22px 79.2px 10% 79.2px;
}

#recipes-p1 {
  margin-top: 11px;
}

.recipe {
  padding: 79.2px - 22px 79.2px 79.2px;
}

.recipe-title-img {
  margin-top: 11px;
}

/* RECIPES */


/* CONTACT */

.contact {
  padding: 79.2px - 22px 79.2px 50% 79.2px;
  font-size: 100%;
}

/*#contact-p1 {
  padding-top: 28.6px;
  padding-bottom: 28.6px;
}*/

.pictograms {
  height:1em;
  width: auto;
}

/* CONTACT */


.nav-padding {
  padding-left: 0;
  padding-right: 0;
}

.active {
  background-color: white;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}